<template>
  <div>
    <div class="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gradient">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <logo class="mx-auto h-16"/>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-app-white py-8 px-4 sm:px-10">
          <form-open class="space-y-6" @submit="submit">
            <validation-errors :form-id="formId"/>
            <debug>{{ model }}</debug>
            <form-text
              :form-id="formId"
              input-id="username"
              form-error-id="username_disabled"
              :label="$t('username')"
              icon="user"
              v-model="model.username"
            ></form-text>

            <form-text
              :form-id="formId"
              input-id="password"
              form-error-id="password_disabled"
              type="password"
              :label="$t('password')"
              icon="shield-check"
              v-model="model.password"
            ></form-text>

            <form-check :form-id="formId" input-id="remember" form-error-id="remember" :label="$t('remember_me')" true-value="1" false-value="0" v-model="model.remember" />

            <app-button class="w-full text-white bg-app-green-dark hover:bg-app-green-darkish" type="submit"
              :disabled="loading"
              :label="$t('log_in')"
              :loading="loading"
              theme="custom"
            />
          </form-open>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiAuthService from '@/services/api/auth';
import config from '@/config';
import Common from '@/mixins/common';
import Logo from '@/components/Logo.vue';
import { SET_USER } from '@/store/mutation-types';

export default {
  components: {
    Logo,
  },
  data() {
    return {
      formId: 'login',
      model: this.emptyModel(),
    };
  },
  mixins: [
    Common,
  ],
  methods: {
    emptyModel() {
      return {
        username: config.env !== 'development' ? '' : 'grit_xEwATuwHisVeoqmO',
        remember: '0',
      };
    },
    modelToRequest() {
      return this.model;
    },
    submit() {
      ApiAuthService.login(this.modelToRequest(), {
        formId: this.formId,
      }).then((response) => {
        this.$store.commit(`auth/${SET_USER}`, response.data.data);

        const location = this.$route.query.redirect ? {
          path: this.$route.query.redirect,
        } : {
          name: 'dashboard',
        };
        this.$router.push(location);
      }).catch(() => {});
    },
  },
};
</script>
